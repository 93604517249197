import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/reset.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import BaiduMap from 'vue-baidu-map'
import './assets/font/fonts.css'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
 
Vue.use( BaiduMap, { ak : 'SRFt6G9GCIFLzu2GxvhOiWoV7QqXb5a1' } )
Vue.use(ElementUI,{size:'mini'})
Vue.config.productionTip = false
router.afterEach((to,from,next) => {

    window.scrollTo(0,0);
    });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
