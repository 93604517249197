import request from '../utils/axios'

// 留言
export function postStay(params){
    return request({
        url:'/api.php/portal/index/message',
        method:'post',
        data:params
    })
}
// 应用领域
export function getApp(params){
    return request({
        url:'/api.php/portal/lists/getcategorypostlists',
        method:'get',
        params:params
    })
}
// banner获取
export function getBanner(params){
    return request({
        url:'/api.php/portal/index/slider?id=1',
        method:'post',
        data:params
    })
}
// 新闻咨询
export function getNew(params){
    return request({
        url:'/api.php/portal/lists/getcategorypostlists',
        method:'get',
        params:params
    })
}
// 产品展示
export function getProducts(params){
    return request({
        url:'/api.php/portal/lists/getcategorypostlists',
        method:'get',
        params:params
    })
}
// 公司介绍
export function getIntroduce(params){
    return request({
        url:'/api.php/portal/pages/read?id=2',
        method:'post',
        data:params
    })
}
// 获取分类
export function getClassIfication(params){
    return request({
        url:'/api.php/portal/categories/subCategories',
        method:'get',
        params:params
    })
}
// 文章详情
export function getArticleDetails(params){
    return request({
        url:'/api.php/portal/articles/read',
        method:'get',
        params:params
    })
}
// 底部配置信息
export function getFooterInfo(params){
    return request({
        url:'/api.php/portal/index/config',
        method:'get',
        params:params
    })
}