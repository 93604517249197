//请求工具类
import axios from 'axios'
// import { getToken } from './auth.js'
export const baseUrl = 'http://www.hongfu-etching.com'

//这是axios请求全局工具类  判断的所有的请求接口状态 
const service = axios.create({
    baseURL: baseUrl,
    timeout: 10000
})
//请求拦截
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
//响应拦截器
service.interceptors.response.use(
    response => {
        // console.log(response)
        const res = response.data
        return res
    },
    error => {
        return Promise.reject(error)
    }
)

export default service