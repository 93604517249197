<template>
    <div class="header">
        <div style="display: flex;">
            <div class="logo">
                <img v-if="logoShow" src="../assets/images/logo.png" width="163px" height="37px" alt="鸿福蚀刻">
                <img v-else src="../assets/images/logo_an.png" width="163px" height="37px" alt="鸿福蚀刻">
            </div>
            <div class="nav">
                <el-menu :default-active="activeIndex" :text-color="logoShow ? '#fff' : '#000'" :active-text-color="logoShow ? '#fff' : '#000'" class="el-menu-demo" style="background: rgba(255, 255, 255, 0);border-bottom:none;" mode="horizontal" @select="handleSelect">
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="home">HOME</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="about">ABOUT&ensp;US</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="field">FIELD</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="products">PRODUCTS</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="workmanship">WORKMANSHIP</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="news">NEWS</el-menu-item>
                    <el-menu-item style="height:82px;line-height: 103px;background:none;margin-right: 30px;font-size: 16px;" index="contact">CONTACT&ensp;US</el-menu-item>
                </el-menu>
            </div>
        </div>
        <div :class="logoShow ? 'language_gu' : 'language_an'" style="position: relative;">
            <el-select v-model="language" popper-class="zhilang" style=" border: none; width: 66px; margin: 0 0 0 16px;" placeholder="请选择">
                <el-option label="EN" value="en"></el-option>
                <el-option label="ZH" value="zh-CN"></el-option>
                <el-option label="DE" value="de"></el-option>
                <el-option label="FR" value="fr"></el-option>
                <el-option label="JA" value="ja"></el-option>
                <el-option label="KO" value="ko"></el-option>
            </el-select>
            <div class="trans" id="trans"></div>
        </div>

    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data () {
        return {
            activeIndex: 'home',
            language: 'en',
            logoShow: true,
        }
    },
    created () {
        // 全局路由守卫
        this.$router.beforeResolve((to, from, next) => {
            next()
            if (this.$route.name != 'home' && this.$route.name != 'workmanship' && this.$route.name != 'newsbranch' && this.$route.name != 'products' && this.$route.name != 'field') {
                this.logoShow = false
            } else {
                this.logoShow = true
            }
            if (to.name == 'newsbranch' || to.name == 'newdetails') {
                this.activeIndex = 'news'
            } else {
                this.activeIndex = to.name
            }
            // 语言切换
            let lang = setInterval(() => {
                if (this.$cookies.get('googtrans')) {
                    let langarr = this.$cookies.get('googtrans').split('/')
                    this.language = langarr[2]
                } else {
                    this.language = 'en'
                }

            }, 500);
        })

    },
    methods: {
        // 导航栏点击
        handleSelect (key, keyPath) {
            // 判断是否重复点击
            if (key === this.activeIndex) {
                return;
            }
            if (key == 'home') {
                this.$router.push({ name: 'home' })
            } else if (key == 'about') {
                this.$router.push({ name: 'about' })
            } else if (key == 'field') {
                this.$router.push({ name: 'field' })
            } else if (key == 'products') {
                this.$router.push({ name: 'products' })
            } else if (key == 'workmanship') {
                this.$router.push({ name: 'workmanship' })
            } else if (key == 'contact') {
                this.$router.push({ name: 'contact' })
            } else if (key == 'news') {
                this.$router.push({ name: 'news' })
            }

        },
    }
}
</script>
<style>
.language_gu .el-input__inner {
    background: rgba(255, 255, 255, 0);
    color: #fff;
    border: none;
    font-size: 16px;
}
.language_an .el-input__inner {
    background: rgba(255, 255, 255, 0);
    color: #000;
    border: none;
    font-size: 16px;
}
</style>
<style scoped lang='less'>
.header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    .logo {
        display: flex;
        height: 100px;
        align-items: center;
        margin-left: 160px;
        margin-right: 68px;
    }
    .language_gu,
    .language_an {
        display: flex;
        align-items: center;
        margin-right: 76px;
    }
}
</style>